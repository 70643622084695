import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.3359 9C14.2689 9 14.8359 8.433 14.8359 6.5C14.8359 4.567 14.2689 4 12.3359 4C10.4029 4 9.83586 4.567 9.83586 6.5C9.83586 8.433 10.4029 9 12.3359 9ZM12.3359 9V12M12.3359 12V21M12.3359 12H14.8359M12.3359 12H9.83586M12.3359 21C7.33596 21 3.83596 17 4.83596 12M12.3359 21C17.3358 21 20.8359 17 19.8359 12M4.83596 12L7.83594 14.5M4.83596 12L1.83594 14.5M19.8359 12L16.8359 14M19.8359 12L22.8359 14"
      stroke="#143333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const AnchorIcon = memo(SvgComponent)
export default AnchorIcon
